import { template as template_86634f80c0a642f996b34cfcbb524e29 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_86634f80c0a642f996b34cfcbb524e29(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
