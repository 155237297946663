import { template as template_e53cac45b6694f7d9eb2c30f6fe0a079 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_e53cac45b6694f7d9eb2c30f6fe0a079(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
