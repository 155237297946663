import { template as template_0ac753237e6a496589697a634641e4cf } from "@ember/template-compiler";
const SidebarSectionMessage = template_0ac753237e6a496589697a634641e4cf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
