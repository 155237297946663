import { template as template_d156555cf61c477d81452f8d3fff99fe } from "@ember/template-compiler";
const FKText = template_d156555cf61c477d81452f8d3fff99fe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
