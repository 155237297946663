import { template as template_e37a3e2e0ca544f78f64031f898c7d5b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const SidebarSectionHeader = template_e37a3e2e0ca544f78f64031f898c7d5b(`
  {{#if @collapsable}}
    <DButton
      @title="sidebar.toggle_section"
      @action={{@toggleSectionDisplay}}
      @forwardEvent={{true}}
      aria-controls={{@sidebarSectionContentId}}
      aria-expanded={{if @isExpanded "true" "false"}}
      class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
    >
      {{yield}}
    </DButton>
  {{else}}
    <span class="sidebar-section-header">
      {{yield}}
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionHeader;
